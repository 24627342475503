var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-tab", {
        attrs: {
          type: "tabcard",
          align: "left",
          height: _vm.tabHeight,
          inlineLabel: true,
          tabItems: _vm.tabItems,
          editable: _vm.editable,
          addTab: !_vm.tabAddDisabled,
          removeTab: !_vm.tabRemoveDisabled,
          removeConditionCol: "accidentStatusCd",
          removeConditionVal: "ISPC000005",
        },
        on: {
          "update:tabItems": function ($event) {
            _vm.tabItems = $event
          },
          "update:tab-items": function ($event) {
            _vm.tabItems = $event
          },
          addTab: _vm.addTab,
          removeTab: _vm.removeTab,
          tabClick: _vm.tabClick,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: { popupParam: _vm.popupParam, setSize: _vm.setSize },
                  on: {
                    "update:popupParam": function ($event) {
                      _vm.popupParam = $event
                    },
                    "update:popup-param": function ($event) {
                      _vm.popupParam = $event
                    },
                    "update:setSize": function ($event) {
                      _vm.setSize = $event
                    },
                    "update:set-size": function ($event) {
                      _vm.setSize = $event
                    },
                    setReportTab: _vm.setReportTab,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
      _c(
        "q-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.prompt,
            callback: function ($$v) {
              _vm.prompt = $$v
            },
            expression: "prompt",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { "min-width": "350px" } },
            [
              _c("q-card-section"),
              _c(
                "q-form",
                { ref: "editForm" },
                [
                  _c(
                    "q-card-section",
                    { staticClass: "q-pt-none" },
                    [
                      _c("c-text", {
                        attrs: {
                          required: "",
                          label: "LBL0002311",
                          name: "reportName",
                        },
                        model: {
                          value: _vm.reportName,
                          callback: function ($$v) {
                            _vm.reportName = $$v
                          },
                          expression: "reportName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "q-card-section",
                    { staticClass: "q-pt-none" },
                    [
                      _c("c-select", {
                        attrs: {
                          required: "",
                          type: "edit",
                          editable: _vm.editable,
                          comboItems: _vm.comboItems,
                          itemText: "codeName",
                          itemValue: "code",
                          label: "LBL0002312",
                          name: "reportTypeCd",
                        },
                        model: {
                          value: _vm.reportTypeCd,
                          callback: function ($$v) {
                            _vm.reportTypeCd = $$v
                          },
                          expression: "reportTypeCd",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-card-actions",
                {
                  staticClass: "text-primary",
                  attrs: { actions: "", align: "right" },
                },
                [
                  _c("q-btn", {
                    attrs: { flat: "", label: _vm.$label("LBLCANCEL") },
                    on: { click: _vm.cancelReportName },
                  }),
                  _c("q-btn", {
                    attrs: { flat: "", label: _vm.$label("LBLADD") },
                    on: { click: _vm.setReportName },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }